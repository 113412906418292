
import { Vue } from "vue-class-component";
import { Register } from "@ui-root/reg";
import "../global-style.css";

@Register({
	name:"su-v-line",
	props: {
	}
})
export default class SuVLine extends Vue {
}
