
import { Vue } from 'vue-class-component';
import { Register, Prop, Emit } from "@ui-root/reg";
import "@ui-root/global-style.css";
import { SuViewCtrlInjectIsMobile } from '@ui-root/outher';

@Register({
	name:"su-modal",
	watch: {
		visible(v){
			console.log(v)
			if (typeof v !== 'undefined') {
				this.locVisable = v;
			}
		},
	},
})
export default class SuModalConfirm extends Vue {
	@Prop({
		type: Boolean,
		default: undefined
	})
	private readonly visible!:boolean|undefined;
	private locVisable = false;
	@Emit('update:visable')
	private updateVisable(visable:boolean):boolean{
		return visable;
	}
	@Emit()
	public close():void{
		if(typeof this.visible === 'undefined'){
			this.locVisable = false;
		}
		this.updateVisable(false);
	}
	public show():void{
		if(typeof this.visible === 'undefined'){
			this.locVisable = true;
		}
		this.updateVisable(true);
	}
	@Prop({
		type: String,
	})
	private readonly title!:string|undefined;
	created():void{
		if (typeof this.visible !== 'undefined') {
			this.locVisable = this.visible;
		}
	}
	@SuViewCtrlInjectIsMobile readonly isMobile!: boolean;
}
