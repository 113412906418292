
import { Options, Vue } from "vue-class-component";

import {func, infos} from "@/../ui/src/main";

@Options({
  components:{
  }
})
export default class HelloWorld extends Vue {
  private get allComponents(){
		let ret:Record<string,typeof infos[string]> = {};
		Object.keys(infos).forEach(key => {
			if(!infos[key].hideInDoc && !infos[key].internalOnly){
				ret[key]=infos[key];
			}
		});
		return ret;
	}
  private isOpenMenu=false;
}
