
import { Register, Prop } from "@ui-root/reg";
import { Vue } from 'vue-class-component';
import { MenuItem } from "@ui-root/types";
import '@ui-root/global-style.css';

@Register({
	name: 'su-h-menu-child',
	internalOnly: true,
	components:{
		// SuHMenuItem,
	},
})
export default class SuHMenuChild extends Vue {
	@Prop({
		type: Array,
		required: true,
	})
	private menu!:MenuItem[];
	@Prop({})
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private itemComponent!:any;
	@Prop({
		type: String,
	})
	private direction!:'right'|'bottom';
}

