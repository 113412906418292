
import { Vue } from 'vue-class-component';
import { Prop, Register } from "@ui-root/reg";
import "../global-style.css";
import VLayout from "./layout-v.vue";
import HLayout from "./layout-h.vue";
import SuMain from "./layout-main.vue";


@Register({
	name:"su-card",
	components:{
		VLayout,
		HLayout,
		SuMain
	}
})
export default class SuCard extends Vue {
	declare $refs: {
		body:HTMLDivElement
		bodyInner:HTMLDivElement
	};
	@Prop({
		type:String,
		default:""
	})
	readonly title!:string;
	@Prop({
		type:Boolean,
		default:false
	})
	readonly collapsible!:boolean;
	isCollaps=false;
	collapsChange():void{
		const realHeight=this.$refs.bodyInner.clientHeight;
		if(this.isCollaps){
			this.$refs.body.style.height=realHeight+"px";
		}else{
			this.$refs.body.style.height=realHeight+"px";//需要先设置一个高度才能应用过渡动画
			setTimeout(()=>this.$refs.body.style.height="0",0);//0秒定时器用于将两个高度设置都能被浏览器读取
		}
		this.isCollaps=!this.isCollaps;
	}
	handleTransitionEnd():void{
		if(!this.isCollaps){
			//动画播放完之后取消掉高度限制
			this.$refs.body.style.height="";
		}
	}
}
