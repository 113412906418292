
import { Vue } from "vue-class-component";
import { Register, Prop } from "@ui-root/reg";

import "../global-style.css";

@Register({
	name:"su-button"
})
export default class SuButton extends Vue {
	@Prop({
		type:String,
		default:()=>{
			return "medium"
		}
	})
	size!:string;
}
