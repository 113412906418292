
import { Register, Prop } from "@ui-root/reg";
import { Vue } from 'vue-class-component';
import SuHMenuChild from "./menu-h-child.vue";
import SuHMenuItem from "./menu-h-item.vue";
import { MenuItem } from "@ui-root/types";
import '@ui-root/global-style.css';

@Register({
	name: 'su-h-menu',
	watch:{
		menu(this:SuHMenu){
			this.splitMenu();
		},
	},
	components:{
		SuHMenuChild,
		SuHMenuItem,
	},
	internalOnly:true,
})
export default class SuHMenu extends Vue {
	@Prop({
		type: Array,
		required: true,
	})
	private menu!:MenuItem[];
	private splitMenu(){
		this.displayItem=[...this.menu];
	}
	private displayItem:MenuItem[]=[];
	created():void{
		this.splitMenu();
	}
}

