
import { Vue } from "vue-class-component";
import { Register } from "@ui-root/reg";
import "@ui-root/global-style.css";

@Register({
	name:"su-auto-direction",
})
export default class SuAutoDirection extends Vue {
}
