
import { Vue } from "vue-class-component";
import { Prop, Register } from "@ui-root/reg";
import { SuViewCtrlInjectIsMobile } from "@ui-root/outher";
import { SuMenu } from "@ui-root/main";
import { MenuItem } from "@ui-root/types";
import "@ui-root/global-style.css";

@Register({
	name:"su-page",
	components: {
		SuMenu,
	}
})
export default class SuMain extends Vue {
	@SuViewCtrlInjectIsMobile readonly isMobile!:boolean;
	@Prop({
		type: String,
		default: '',
	})
	private title!:string;
	@Prop({
		type: String,
		default: '',
	})
	private logo!:string;
	@Prop({
		type: Array,
		default: '',
	})
	private menu!:MenuItem[];
	private isOpenMenu = false;
}
