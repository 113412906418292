
import { Vue } from "vue-class-component";
import { Register } from "@ui-root/reg";
import "../global-style.css";

@Register({
	name:"su-h-layout",
})
export default class SuHLayout extends Vue {
}
