
import { Vue } from 'vue-class-component';
import { Register, Prop } from "@ui-root/reg";
import "../global-style.css";


@Register({
	name:"su-table",
	props: {
		data:{
			type:Array,
			default:()=>[]
		}
	}
})
export default class SuTable extends Vue {
	// @Prop({
	// 	type:Array,
	// 	default:()=>[]
	// })
	// data!:any[];
	@Prop({
		type:Array,
		default:()=>[]
	})
	// eslint-disable-next-line
	columns!:any[];
}
