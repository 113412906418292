
import { Vue } from 'vue-class-component';
import { Register, Prop } from "@ui-root/reg";
import treeItem from "./tree-item.vue" 


import "../../global-style.css";

@Register({
	name:"su-tree-item",
	components:{
		treeItem
	},
	internalOnly: true,
})
export default class SuTreeItem extends Vue {
	readonly isExpand=false;
	@Prop({
		type:Number,
		default:()=>{
			return 0;
		}
	})
	readonly deep!:number;
	@Prop({
		type:String,
		default:()=>{
			return "medium"
		}
	})
	readonly size!:string;
	@Prop({
		type:Object,
		default:()=>{
			return {}
		}
	})
	readonly item!:Record<string, unknown>;
	@Prop({
		type:[String,Function],
		default:()=>{
			return "label"
		}
	})
	readonly label!:string|((item:Record<string, unknown>)=>string);
	@Prop({
		type:[String,Function],
		default:()=>{
			return "value"
		}
	})
	readonly value!:string|((item:Record<string, unknown>)=>string|number);
	@Prop({
		type:[String,Function],
		default:()=>{
			return "children"
		}
	})
	readonly children!:string|((item:Record<string, unknown>)=>Record<string, unknown>[]);
	@Prop({
		type:Object,
		default:()=>{
			return {}
		}
	})
	readonly current!:Record<string, unknown>;
	@Prop({
		type:[String,Number],
		default:()=>{
			return undefined
		}
	})
	readonly currentValue!:string|number|undefined;

	get labelData():string{
		if(typeof this.label == "string"){
			return (this.item[this.label]||"") as string;
		}
		return this.label(this.item);
	}
	get valueData():string|number|undefined{
		if(typeof this.value == "string"){
			return (this.item[this.value]||"") as string|number|undefined;
		}
		return this.value(this.item);
	}
	get childrenData():Record<string, unknown>[]{
		if(typeof this.children == "string"){
			return (this.item[this.children]||[]) as Record<string, unknown>[];
		}
		return this.children(this.item);
	}
	private handleClick(ev:MouseEvent):void{
		var classList = (ev.target as HTMLElement).className.split(" ");
		if(classList.indexOf("star-ui-icon-arrow-down")!=-1 || classList.indexOf("star-ui-icon-arrow-right")!=-1){
			return;
		}
		this.$emit("node-click",this.item,this.valueData,this);
	}
	haandleChildNodeClick(item:Record<string, unknown>,value:string|number|undefined,node:Record<string, unknown>):void{
		this.$emit("node-click",item,value,node);
	}
}
