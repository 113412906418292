
import { Vue } from "vue-class-component";
import { Register, Prop, Emit } from "@ui-root/reg";
import moment from "moment";
import CallRender from "./call-render";


import "../global-style.css";

const weekNames = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
interface DateBaseInfo{
	display:number;
	isPrevious:boolean;
	isNext:boolean;
	week:string;
	date:moment.Moment;
}

@Register({
	name:"su-calendar",
	components:{
		vnodes: CallRender,
	}
})
export default class SuCalendar extends Vue {
	@Prop({
		type:[Date,String],
		default:()=>{
			return new Date();
		}
	})
	public readonly displayMonth!:Date|string;
	private dYear=0;
	private dMonth=0;
	private get displayMonthDate():moment.Moment{
		let date=moment(this.displayMonth);
		date.date(1);
		date.add(this.dYear,"y").add(this.dMonth,"M");
		return date;
	}
	@Prop({
		type:Array,
		default:()=>{
			return weekNames;
		}
	})
	public readonly weekName!:string[];

	@Prop({
		type:Number,
		default:0,
		validator: (input:number): boolean=>{
			return input>=0&&input<7
		}
	})
	public weekOffect!:number;

	private get displayHead():{
		display:string;
		week:string;
	}[]{
		const mList=this.weekName.map((item,index)=>({
			display: item,
			week:weekNames[index],
		}))
		return [
			...mList.slice(this.weekOffect),
			...mList.slice(0,this.weekOffect),
		];
	}
	private get displayData():DateBaseInfo[][]{
		let res:DateBaseInfo[][]=[[]];
		let now=res[0];
		
		const nowDay = moment(this.displayMonthDate);
		nowDay.set('date',1);
		let reqPreviousDays=nowDay.day()-this.weekOffect;
		if(reqPreviousDays<0) reqPreviousDays+=7;
		nowDay.add(-reqPreviousDays,'days');
		let isNotNext=true;
		while(isNotNext||now.length<7){
			if(now.length==7){
				now=[];
				res.push(now);
			}
			now.push({
				display:nowDay.date(),
				isPrevious:isNotNext&&this.displayMonthDate.month()!=nowDay.month(),
				isNext:!isNotNext,
				week:weekNames[nowDay.day()],
				date:moment(nowDay),
			});
			nowDay.add(1,'days');
			isNotNext=this.displayMonthDate.year()>=nowDay.year()&&this.displayMonthDate.month()>=nowDay.month();
		}
		return res;
	}
	@Prop({
		type:Function,
		default:undefined
	})
	public readonly renderCell!:(arg:DateBaseInfo)=>unknown;

	private showSelYear=false;
	private dShowSelYear=0;
	private showSelMonth=false;

	@Emit()
	private dateClick(data:DateBaseInfo):DateBaseInfo{
		return data;
	}
}
