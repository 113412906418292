
import { Vue } from 'vue-class-component';
import { Register, Prop, Emit } from "@ui-root/reg";
import "../global-style.css";

@Register({
	name:"su-input"
})
export default class SuInput extends Vue {
	@Prop({
		type:[String,Number],
		default:""
	})
	modelValue!:string|number;
	declare $refs:{
		input:HTMLInputElement,
	};
	@Prop({
		type:String,
		default:""
	})
	name!:string;
	@Prop({
		type:String,
		default:"medium"
	})
	size!:string;
	isFocused=false;
	@Emit("update:modelValue")
	emitInput(data:string):string{
		return data;
	}
	handleInput(event:InputEvent):void{
		if(event.target){
			var target=event.target as HTMLInputElement;
			this.$emit("update:modelValue",target.value);
			this.emitInput(target.value)
			this.$nextTick(()=>target.value=(this.modelValue as string));//数字会自动转换成字符串
			// return target.value;
		}
		// return "";
	}
	handleFocus():void{
		this.isFocused=true;
	}
	handleBlur():void{
		this.isFocused=false;
	}
}
