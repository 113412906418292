
import { Vue } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { Register, Prop } from "@ui-root/reg";
import { TypeFormLabelAlign } from "@ui-root/outher";
import { Bus } from "./form.vue";

import "@ui-root/global-style.css";

export const InjectLabelWidth = Symbol('InjectLabelWidth');
export const InjectLabelAlign = Symbol('InjectLabelAlign');
export const InjectEventBus = Symbol('InjectEventBus');

@Register({
	name:"su-form-item",
	hideInDoc:true,
	watch:{
		label(this:SuButton){
			this.bus&&this.bus.labelUpdate(this);
		}
	}
})
export default class SuButton extends Vue {
	declare $refs: {
		labelDiv: HTMLDivElement
	};
	@Prop({
		type:String,
		default:()=>{
			return 'label';
		}
	})
	readonly label!:string;
	@Prop({
		type:[String, Number],
	})
	readonly labelWidth!:string|number|undefined;
	@Inject({from:InjectLabelWidth,default:undefined})
	readonly pLabelWidth!:string|number|undefined;
	@Prop({
		autoType: "FormLabelAlign",
	})
	readonly labelAlign!:TypeFormLabelAlign;
	@Inject({from:InjectLabelAlign,default:"right"})
	readonly pLabelAlign!:TypeFormLabelAlign;
	@Prop({
		type:Number,
		default:()=>{
			return 24;
		}
	})
	readonly col!:number;
	private get labelStyle():Record<string, unknown>{
		const ret:Record<string, unknown>={};
		const labelWidth = this.labelWidth || this.pLabelWidth;
		if(labelWidth){
			ret.width=typeof labelWidth === 'number' ? `${labelWidth}px` : labelWidth;
		}
		return ret;
	}
	@Inject({from:InjectEventBus})
	readonly bus!:Bus|undefined;
	created(){
		if(this.bus){
			this.bus.addItem(this);
		}
	}
	beforeUnmount(){
		if(this.bus){
			this.bus.removeItem(this);
		}
	}
}
