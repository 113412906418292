
import { Vue } from 'vue-class-component';
import BasePopper from "./common/base-popper.vue";
import { Register, Emit, Prop } from "@ui-root/reg";
import { SuViewCtrlInjectIsMobile } from "@ui-root/outher";
import SuTree from "./tree/index.vue";


import "../global-style.css";

@Register({
	name:"su-select",
	components:{
		BasePopper,
		SuTree
	},
	watch:{
		isFocused(v){
			if(v){
				if(this.$el){
					if(this.$el.clientWidth){
						this.width=this.$el.clientWidth;
					}
				}
			}
		},
		modelValue(){
			this.updateTree();
		}
	}
})
export default class SuSelect extends Vue {
	mounted():void{
		this.updateTree();
	}
	declare $refs:{
		tree:SuTree
	};
	@Prop({
		type:[String,Number,Array],
		default:""
	})
	readonly modelValue!:string;
	@Prop({
		type:String,
		default:""
	})
	readonly name!:string;
	@Prop({
		type:String,
		default:"medium"
	})
	private size!:string;
	@Prop({
		type:Array,
		default:function(){return [];}
	})
	readonly options!:Record<string, unknown>[];
	@Prop({
		type:String,
		default:"value"
	})
	readonly valueName!:string;
	@Prop({
		type:String,
		default:"label"
	})
	readonly labelName!:string;
	@Prop({
		type:[String,Function],
		default:()=>{
			return "children"
		}
	})
	children!:string|((item:Record<string, unknown>)=>Record<string, unknown>[]);
	@Prop({
		type:Boolean,
		default:false
	})
	readonly showAsTree!:boolean;
	@Prop({
		type:String,
		default:""
	})
	readonly pooperClass!:string;
	isFocused=false;
	@SuViewCtrlInjectIsMobile readonly isMobile!: boolean;
	get valueMap():Map<string|number,string>{
		const map=new Map();
		if(this.showAsTree){
			let task:Record<string, unknown>[] = [];
			task.push(...this.options);
			while(task.length){
				const item = task.pop() as Record<string, unknown>;
				let itemValue:string|number|undefined;
				if(typeof this.valueName == "string"){
					itemValue = (item[this.valueName]) as string|number|undefined;
				// }else{
				// 	itemValue = this.value(this.current);
				}
				if(itemValue!=undefined){
					map.set(itemValue,(this.labelName in item)?item[this.labelName]:itemValue);
				}
				let children:Record<string, unknown>[]|undefined;
				if(typeof this.children == "string"){
					children = (item[this.children]) as Record<string, unknown>[]|undefined;
				}else{
					children = this.children(item);
				}
				children&&task.push(...children);
			}
		}else{
			this.options.map((item)=>{
				if(this.valueName in item){
					const value=item[this.valueName];
					const label=(this.labelName in item)?item[this.labelName]:value
					map.set(value,label);
				}
			});
		}
		return map;
	}
	get showValue():string{
		if(this.valueMap.has(this.modelValue)){
			return this.valueMap.get(this.modelValue) as string;
		}
		return this.modelValue;
	}
	private width=280;
	private updateTree(){
		if(this.modelValue&&this.showAsTree){
			this.$refs.tree.setCurrentByValue(this.modelValue);
		}
	}
	@Emit("update:modelValue")
	handleSelect(value:string):string{
		//this.$emit("update:modelValue",value);
		this.isFocused=false;
		return value;
	}
	@Emit("update:modelValue")
	haandleNodeClick(item:Record<string, unknown>,value:string|number|undefined):string|number|undefined{
		this.isFocused=false;
		this.$refs.tree.setCurrentByValue(this.modelValue);
		return value;
	}
}
