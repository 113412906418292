
import { Vue } from "vue-class-component";
import { Prop, Register } from "@ui-root/reg";
import { SuViewCtrlInjectClassName, SuViewCtrlInjectIsMobile } from "@ui-root/outher";
import MenuBox from "../menu/menu-h/menu-h-child.vue";
import MenuBoxMobile from "../menu/menu-v/index.vue";
import MenuItem from "../menu/menu-h/menu-h-item.vue";
import "@ui-root/global-style.css";
import { ContextMenuItem } from "@ui-root/types";

@Register({
	name:"su-context-menu-box",
	components: {
		MenuBox,
		MenuBoxMobile,
	}
})
export default class SuMain extends Vue {
	private itemComponent = MenuItem;
	declare $refs:{
		mask:HTMLDivElement,
	};
	@SuViewCtrlInjectIsMobile readonly isMobile!:boolean;
	@SuViewCtrlInjectClassName readonly ctrlClass!:string[];
	private get component(){
		if(this.isMobile){
			return MenuBoxMobile;
		}
		return MenuBox;
	}
	@Prop({
		type: [Array, Function],
		required: true,
	})
	private menu!:ContextMenuItem[] | ((ev: MouseEvent) => ContextMenuItem[]);
	private isOpenMenu = false;
	private currentMenu: ContextMenuItem[] = [];
	private currentX = 0;
	private currentY = 0;
	private handleContextMenu(ev: MouseEvent){
		// ev.stopPropagation();
		ev.preventDefault();
		if(typeof this.menu === 'function'){
			this.currentMenu = this.menu(ev) || [];
		}else{
			this.currentMenu = this.menu || [];
		}
		// this.currentMenu=[{title: 'aa', children: [ {title: 'aa.1', click: () => console.log('aa.1 click')} ]}, {title: 'bb', click: () => console.log('bb click')}];
		this.currentX=ev.clientX;
		this.currentY=ev.clientY;
		this.isOpenMenu = true;
	}
	handleMaskClick(ev: MouseEvent){
		if (ev.target === this.$refs.mask) {
			this.isOpenMenu = false;
		}
	}
	handleItemClick(item: ContextMenuItem){
		if (item.click) {
			this.isOpenMenu = false;
			item.click();
		}
	}
}
