
import { Register, Prop, Emit } from "@ui-root/reg";
import { Vue } from "vue-class-component";
import SuHMenuChild from "./menu-h-child.vue";
import isFocus from "@ui-root/directives/is-focus";
import { MenuItem } from "@ui-root/types";
import '@ui-root/global-style.css';

@Register({
	name: 'su-h-menu-item',
	internalOnly: true,
	components:{
		SuHMenuChild,
	},
	directives:{
		isFocus,
	}
})
export default class SuHMenuItem extends Vue {
	isFocus=false;
	@Prop({
		type: Object,
	})
	private item!:MenuItem;
	private selfClass = SuHMenuItem;
	@Prop({
		type: String,
		default:'right',
	})
	private childDirection!:'right'|'bottom';
}
