
import { Vue } from "vue-class-component";
import { Register } from "@ui-root/reg";
import "../global-style.css";

@Register({
	name:"su-main",
	props: {
	}
})
export default class SuMain extends Vue {
}
