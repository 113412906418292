
import { Vue } from 'vue-class-component';
import { Watch } from "vue-property-decorator";
import { Register, Prop, Emit } from "@ui-root/reg";
import "../global-style.css";

@Register({
	name:"su-switch"
})
export default class SuInput extends Vue {
	declare $refs:{
		input:HTMLInputElement
	};
	@Prop({
		type:Boolean,
		default:true
	})
	modelValue!:boolean;
	@Prop({
		type:String,
		default:""
	})
	name!:string;
	@Prop({
		type:String,
		default:"medium"
	})
	size!:string;
	@Emit("update:modelValue")
	private handeleChange():boolean{
		const nowChecked = this.$refs.input.checked;
		this.$refs.input.checked = this.modelValue;
		return nowChecked;
	}
	@Watch("modelValue")
	private modelValueChange(newValue:boolean){
		this.$refs.input.checked = newValue;
	}
}
